.cardList {
  list-style: none;
  margin: 0;
  padding: 0;
  @apply flex;
  @apply flex-col;
  flex-wrap: wrap;
}

.cardItem {
  list-style: none;
  margin: 0;
  padding: 0;
  flex-basis: 100%;
  @apply p-2;
}

@screen md {
  .cardList {
    @apply flex-row;
  }

  .cardItem {
    flex-basis: 33%;
  }
}

@screen lg {
  .cardItem {
    flex-basis: 25%;
    @apply p-4;
  }
}
